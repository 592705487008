import css from '@utils/css'

import capterra from '@images/capterra.svg';
import crozdesk from '@images/crozdesk.svg';
import g2ReviewStars from '@images/g2-review-stars.svg';
import softwareAdvice from '@images/softwareAdvice.svg';
import trackAppSitesUse from '@images/home-cards/employee-monitoring-software-traqq.png';
import controlProductivityTeamTimelines from '@images/home-cards/time-tracking-software-traqq.png';
import driveTeamEfficiency from '@images/home-cards/billable-hours-reporting-traqq.png';
import driveTeamEfficiencyAdditional from '@images/home-cards/drive-team-efficiency-additional.png';
import driveTeamEfficiencyMobile from '@images/home-cards/drive-team-efficiency-mobile.png';
import idleTimeReporting from '@images/home-cards/idle-time-reporting-traqq.png';
import automatedTimesheets from '@images/home-cards/automated-timesheets-traqq.png';
import leaveManagementPto from '@images/home-cards/pto-reporting-traqq.png';
import leaveManagementPtoMobile from '@images/home-cards/leave-management-pto-mobile.png';
import useCasesFreelancers from '@images/use-cases-cards/time-tracker-app-for-freelancers-traqq.png';
import useCasesSmallBusiness from '@images/use-cases-cards/time-tracker-for-small-business-traqq.png';
import useCasesEnterprise from '@images/use-cases-cards/time-tracker-software-for-enterprise-traqq.png';
import trustpilot from '@images/trustpilot.svg';
import trustRadius from '@images/trustRadius.svg';
import getApp from '@images/getApp.svg';

//badges
import crozdeskHappiestusers from '@images/home-badges/crozdesk-happiest-users.png';
import crozdeskLeader from '@images/home-badges/crozdesk-leader-2023.png';
import trustRadiusMostLoved from '@images/home-badges/trustradius-most-loved-2023.png';
import trustRadiusTopRated from '@images/home-badges/trustradius-top-rated-2023.png';
import highPerformer from '@images/home-badges/high-perfomer.png';
import usersLoveUs from '@images/home-badges/users-love-us.png';
import easiestAdmin from '@images/home-badges/easiest-admin.png';

export default {
    cards: [
        {
            alt: 'Employee Monitoring Software - Traqq',
            desc: 'Track app and websites usage',
            id: 1,
            image: trackAppSitesUse,
            title: 'Employee Monitoring Software',
            url: 'https://traqq.com/support/monitoring-teams/apps-and-websites',
            hoverColor: 'red'
        },
        {
            alt: 'Time tracking software - Traqq',
            desc: 'Control productivity and team timelines',
            id: 2,
            image: controlProductivityTeamTimelines,
            title: 'Employee Time Tracker',
            url: 'https://traqq.com/support/monitoring-teams/dashboard',
            hoverColor: 'violet'
        },
        {
            alt: 'Track billable hours reporting - Traqq',
            altMobile: 'Billable hours reporting summary - Traqq',
            desc: 'Gather essential data with our advanced billable hours reports',
            id: 3,
            widthProp: 'full',
            image: driveTeamEfficiency,
            imageMobile: driveTeamEfficiencyMobile,
            imageAdditional: driveTeamEfficiencyAdditional,
            title: 'Drive Team Efficiency',
            url: 'https://traqq.com/support/reports/weekly-summary',
            hoverColor: 'gray'
        },
        {
            alt: 'Track idle time reporting - Traqq',
            desc: 'Track and analyze idle time for efficiency',
            id: 4,
            image: idleTimeReporting,
            title: 'Idle Time Reporting',
            url: 'https://traqq.com/support/reports/idle-time-report',
            hoverColor: 'blue'
        },
        {
            alt: 'Automated timesheets - Traqq',
            desc: 'Streamline time tracking, attendance and cost control',
            id: 5,
            image: automatedTimesheets,
            title: 'Automated Timesheets',
            url: 'https://traqq.com/support/reports/time-and-activity',
            hoverColor: 'yellow'
        },
        {
            alt: 'PTO reporting - Traqq',
            altMobile: 'Time-off reporting - Traqq',
            desc: 'Handle time off requests with seamless team coordination and centralized leave management',
            id: 6,
            widthProp: 'full',
            image: leaveManagementPto,
            imageMobile: leaveManagementPtoMobile,
            title: 'Leave Management and PTO',
            url: 'https://traqq.com/support/managing-organization/time-off',
            hoverColor: 'green'
        },
    ],
    useCases: [
        {
            alt: 'Time tracker app for freelancers - Traqq',
            desc: 'Freelancers',
            id: 1,
            image: useCasesFreelancers,
            title: 'Freelancers',
            url: `${ process.env.NEXT_PUBLIC_APP_URL }/product/time-tracking-software-for-freelancers/`
        },
        {
            alt: 'Time tracker for small business - Traqq',
            desc: 'Small Business',
            id: 2,
            image: useCasesSmallBusiness,
            title: 'Small Business',
            url: `${ process.env.NEXT_PUBLIC_APP_URL }/product/time-tracking-app-for-small-businesses/`
        },
        {
            alt: 'Time tracker software for enterprise - Traqq',
            desc: 'Enterprise',
            id: 3,
            image: useCasesEnterprise,
            title: 'Enterprise',
            url: `${ process.env.NEXT_PUBLIC_APP_URL }/product/enterprise-time-tracking-software/`
        }
    ],
    reviews: [
        {
            alt: 'G2 reviews rating',
            id: 1,
            link: 'https://www.g2.com/products/traqq/reviews',
            src: g2ReviewStars
        },
        {
            alt: 'Software Advice',
            id: 2,
            link: 'https://www.softwareadvice.com/project-management/traqq-profile/',
            src: softwareAdvice
        },
        {
            alt: 'Crozdesk',
            id: 3,
            link: 'https://crozdesk.com/software/traqq',
            src: crozdesk
        },
        {
            alt: 'Trustpilot',
            id: 4,
            link: 'https://www.trustpilot.com/review/traqq.com',
            src: trustpilot
        },
        {
            alt: 'Capterra',
            id: 5,
            link: 'https://www.capterra.com/p/230726/Traqq/',
            src: capterra
        },
        {
            alt: 'TrustRadius',
            id: 6,
            link: 'https://www.trustradius.com/products/traqq/reviews',
            src: trustRadius
        },
        {
            alt: 'GetApp',
            id: 7,
            link: 'https://www.getapp.com/project-management-planning-software/a/traqq/',
            src: getApp
        }
    ],
    opinions: [
        {
            id: 1,
            author: 'Aala',
            position: 'Business owner',
            bodyFull: 'I\'ve found Traqq\'s tracking features and user autonomy incredibly helpful in managing my time. The customizations offer a level of flexibility perfect for any project.',
            body: "I've found Traqq's tracking features and user autonomy incredibly helpful in managing my time. The customizations offer a level of flexibility perfect for any project.",
            source: 'G2',
            url:'https://www.g2.com/products/traqq/reviews/traqq-review-7979682'
        },
        {
            id: 2,
            author: 'Lauren H.',
            position: 'AR Account Manager',
            body: 'Traqq provides detailed reports and diverse analytics. It\'s helpful to see data on user productivity and consolidated team productivity.',
            source: 'G2',
            url:'https://www.g2.com/products/traqq/reviews/traqq-review-7733476'
        },
        {
            id: 3,
            author: 'Abdullah Z.',
            position: 'Shopify',
            bodyFull: 'I\'ve been using Traqq for the past six months and it has completely transformed the way I manage my time. As a small business owner, it\'s essential for me to keep track of my team\'s hours and bill my clients accurately. Traqq has helped me do just that. The interface is user-friendly and it\'s easy for me to track billable hours, manage finances and gain valuable insights into my business operations. It\'s helped me identify inefficiencies and make data-driven decisions to streamline my workflow and improve productivity. I highly recommend Traqq to any small business owner looking to improve their time management.',
            body: "The interface is user-friendly and it's easy for me to track billable hours, manage finances and gain valuable insights into my business operations.",
            source: 'G2',
            url:'https://www.g2.com/products/traqq/reviews/traqq-review-7733476'
        },
        {
            id: 4,
            author: 'Andrea C.',
            position: 'Project Manager',
            bodyFull: 'My team was trying to look for a program or app that would be easy to handle and keep track of since we became a fully remote company. Traqq has a great user interface and features that make time and productivity tracking easier. It\'s very intuitive so it\'s very difficult to get lost using it. I\'m also grateful that they offer great customer service and also enough privacy so the team can feel comfortable enough with us using a tracking program.',
            body: "Traqq has a great user interface and features that make time and productivity tracking easier. It's very intuitive so it's very difficult to get lost using it.",
            source: 'G2',
            url:'https://www.g2.com/products/traqq/reviews/traqq-review-5441351'
        },
        {
            id: 5,
            author: 'Mihai',
            url: 'https://www.g2.com/products/traqq/reviews/traqq-review-5438073',
            position: 'Project Manager',
            bodyFull: 'I have liked the way that the performance of the freelancers is nicely and graphically represented concerning the time that they have spent on their work. The Graphical User Information of this Tracking Desktop App is quite impressive.',
            body: "I have liked the way that the performance of the freelancers is nicely and graphically represented concerning the time that they have spent on their work.",
        },
        {
            author:'George',
            id:6,
            url:'https://www.g2.com/products/traqq/reviews/traqq-review-5433454',
            position:'Engineer',
            bodyFull:"For me as a first time user, I didn't use a work tracking app as complex as this one.  Firstly, I was impressed by the ability of taking screenshots at ten minutes intervals in which you showcase you work throughout the day. A benefit from this feature is that you don't need to upload them by yourself and you can pause Traqq anytime you are not working. The simplicity of understanding the app as a first user was a pleasant surprise as I got the hang of it after 10 minutes of navigating through the menus presented.",
            body:"The simplicity of understanding the app as a first user was a pleasant surprise as I got the hang of it after 10 minutes of navigating through the menus presented.",
            source:'G2'
        },
        {
            id:7,
            author:'Ashton',
            url:'https://www.g2.com/products/traqq/reviews/traqq-review-8274244',
            position:'Business Owner',
            bodyFull:'The user activity levels detection feature in Traqq is quite insightful. It helps us understand how engaged our team members are during their working hours.',
            body: "The user activity levels detection feature in Traqq is quite insightful. It helps us understand how engaged our team members are during their working hours.",
            source:'G2'
        },
        {
            id:8,
            author:'James',
            url:'https://www.g2.com/products/traqq/reviews/traqq-review-5450299',
            position:'Business Owner',
            bodyFull:"I like the time tracking visualization showing activity and downtime. A user (or manager) can see in real-time or in a report form a worker’s productivity: green or yellow illustrating high productivity and red showing low productivity based on keystrokes and mouse clicks. It also provides text report functionality, where you can describe and report work being done, like reading an article. This application is straightforward and powerful in use and effectiveness.",
            body: "A user (or manager) can see in real-time or in a report form a worker’s productivity: green or yellow illustrating high productivity and red showing low productivity based on keystrokes and mouse clicks.",
            source:'G2'
        },
        {
            id:9,
            author:'Reham',
            url:'https://www.g2.com/products/traqq/reviews/traqq-review-5321120',
            position:'Software Engineer',
            body:"Traqq has many features that I've loved, one of them being that it shows my progress per app, so I can figure out and have a big picture of my productivity.",
            source:'G2'
        },
        {
            id:10,
            author:'Taherah J.',
            url: 'https://www.g2.com/products/traqq/reviews/traqq-review-5234151',
            position:'Frontend Developer',
            bodyFull:"I found this productivity app simple and easy to use. Invoices and expenses were effortless and functional to view in this fully integrated and automated app at any time. The time tracker was straightforward and can be viewed in both places, at the top of the dashboard and at the beginning of the latest project. Productivity levels on all projects are displayed comfortably. Clients can monitor the rate and capacity of work output on all levels. This app offers many helpful and different reports for managing and outlining performance levels seen when employees all contribute to a company's success.",
            body: "The time tracker was straightforward and can be viewed in both places, at the top of the dashboard and at the beginning of the latest project.",
            source:'G2'
        },
        {
            id:11,
            author:'Mamoona Ghaffar',
            position:'Project Manager',
            bodyFull:"I absolutely love TraQQ for its user-friendly interface and seamless navigation. The platform's intuitive design makes it easy to track and manage projects effortlessly. Additionally, the robust reporting and analytics features provide valuable insights, helping me optimize team performance effectively.",
            body:"I absolutely love TraQQ for its user-friendly interface and seamless navigation. The platform's intuitive design makes it easy to track and manage projects effortlessly.",
            source:'Crozdesk'
        },
        {
            id:12,
            author:'Shenali Fernando',
            position:'Project Manager',
            bodyFull:"Traqq has improved the efficiency of time tracking for everyone with its user-friendly interface and easy navigation. The inclusion of an anti-cheating algorithm ensures the maintenance of trust within our team, as it promotes honesty. However, there is room for further improvement. Integration with other tools and software would enhance Traqq's functionality, and the addition of a built-in payroll feature would provide greater convenience in managing our team's payments.",
            body:"Traqq has improved the efficiency of time tracking for everyone with its user-friendly interface and easy navigation.",
            source:'Crozdesk'
        },
        {
            id:13,
            author:'Eliana',
            position:'Recruiter in Argentina',
            bodyFull:"Simple yet complete, I've been using the web dashboard + the app, which integrates nicely with the taskbar for easy tracking. In addition, it allows you to automatize reports (receive daily/weekly monthly) by email, so you don't have to log in and do a manual check if you want to follow the progress of a specific team/team member. It is very easy to use and customizable, depending on the flexibility you want to give the team.",
            body:"Simple yet complete, I've been using the web dashboard + the app, which integrates nicely with the taskbar for easy tracking.",
            source:'Capterra'
        },
        {
            id:14,
            author:'Thomas',
            position:'Accounts Manager in Canada',
            body:"The ethical tracking feature is very reassuring. I love how it's built not to spy on employees and doesn't record sensitive data like passwords and private messages.",
            source:'Capterra'
        },
        {
            id:15,
            author:'Rachel',
            position:'Human Resources Officer in Canada',
            body:"The app is fantastic for tracking time, even when offline. It syncs up once you get back online, ensuring accurate and verifiable tracking regardless of connectivity.",
            source:'Capterra'
        },
        {
            id:16,
            author:'Sidra',
            position:'Scrum Master in Australia',
            bodyFull:"Overall, Traqq helped me and my team monitoring and analysing our work efficiency and getting us better organised with higher transparency in the Pandemic times. Highly recommended!This app is an efficient time tracker which monitors Team productivity and performance by calculating the working hours user is actually working on the assigned tasks or sitting idle, false activity, activity level , screenshot capture while maintaining user privacy. This app also helps in generating reports, adding manual time on request and provides a download option as PDF. This app really helps team get better organised with an improved efficiency. This app plays a great role for freelancers/remote teams as well.",
            body:"Overall, Traqq helped me and my team monitoring and analysing our work efficiency and getting us better organised with higher transparency in the Pandemic times.",
            source:'Capterra'
        },
        {
            id:17,
            author:'Alexandra',
            position:'Project Manager in Brazil',
            bodyFull:"As a Project Manager responsible for a team of 11 specialists as well as a freelancer myself, I see multiple advantages of the Traqq app. Let me highlight some of them from both perspectives: (1) User privacy - although the app takes screenshots or records activity (optional) every 10 minutes, they´re blurred. Meaning that as an employer, I still see what a specialist was working on without violating their privacy. (2) Activity monitoring - this is an amazing feature for employers! I can monitor which apps a freelancer opened during their work time and see how much time was spent on using each. So if a significant amount of time was spent on non-work-related activity - well, it´s time for a serious conversation. (3) Anti-mouse-moving feature - in my opinion, it´s an extremely valuable one! If a freelancer is trying to cheat the time tracker, it simply won´t work. Traqq app detects this fake activity and it won´t be recorded. These are top-3 key features that I wanted to highlight, although there´s so much more! I chose to emphasize these as they help create a sense of trust and responsibility between an employer and freelancers.",
            body:"As a Project Manager responsible for a team of 11 specialists as well as a freelancer myself, I see multiple advantages of the Traqq app.",
            source:'Capterra'
        },
        {
            id:18,
            author:'Indya',
            position:'HR Consultant in Canada',
            bodyFull:"Before using Traaq, it was not always easy to ensure that the time paid corresponded to the time worked. Traqq allow us to monitor the time worked, but also to have a better understanding of the organization of the work and the positions over time. From an HR point of view, it allows us to update job descriptions accordingly, but also to be able to discuss with the employee their strengths and areas for improvement based on the time spent on a task. We use the tool to optimize the work, but also the productivity of our employees, and it allows us to offer work flexibility according to that.",
            body:"Traqq allow us to monitor the time worked, but also to have a better understanding of the organization of the work and the positions over time.",
            source:'Capterra'
        },
        {
            id:19,
            author:'Lissy',
            position:'People & Culture Manager in UK',
            bodyFull:"As an HR Professional, I juggle multiple responsibilities in the company including monitoring freelance workers. With Traqq, I can easily oversee our freelance workers' activities no matter how many they are in just one platform. I can see all the essential data in one glance, in real-time, all of which is safely stored. The platform is user-friendly and it's easy to navigate around different tabs. I personally love the employee monitoring tool report as I can see if there's a drop in the productivity of our freelancers. And because of its desktop screenshot and recording feature, I am able to see the progress of their work and what they are working on during that day. Since I'm getting a detailed team analysis and report, I am able to calculate the amount our freelancer earns for the hours they worked for us. Traqq improved our company's operational efficiency and it gives me more time to focus on other HR responsibilities.",
            body:"With Traqq, I can easily oversee our freelance workers' activities no matter how many they are in just one platform. I can see all the essential data in one glance, in real-time, all of which is safely stored.",
            source:'Capterra'
        },
        {
            id:20,
            author:'Trent',
            position:'HR Manager in Japan',
            bodyFull:"As a HR manager who looks after remote workers. I can say this is simply the best. Everything you need to see is intuitive and accessible with a simple click. You can literally see the time spent on each app and with the inbuilt pay rate calculations, payroll for work reported on vs work done is a breeze. Reporting is no different, reports are easily generated for your team and can be accessed by other HR personnel who may need to access it at a later date.",
            body:"As a HR manager who looks after remote workers. I can say this is simply the best. Everything you need to see is intuitive and accessible with a simple click.",
            source:'Capterra'
        }
    ],
    numbers: [
        {
            id:1,
            value:'1000+',
            text:'and counting 5-star reviews',
        },
        {
            id:2,
            value:'139',
            text:'Countries used across the globe',
        },
        {
            id:3,
            value:'16,000,000',
            text:'Hours tracked',
        },
        {
            id:4,
            value:'300+',
            text:'New teams monthly',
        }
    ],
    badges: [
        {
            alt: 'Crozdesk Employee Monitoring Leader 2023',
            id: 1,
            image: crozdeskLeader
        },
        {
            alt: 'High User Satisfaction Award',
            id: 2,
            image: crozdeskHappiestusers
        },
        {
            alt: 'Top Rated by trustradius',
            id: 3,
            image: trustRadiusTopRated
        },
        {
            alt: 'most loved by trustradius',
            id: 4,
            image: trustRadiusMostLoved
        },
        {
            alt: 'Easiest Admin Award',
            id: 5,
            image: easiestAdmin,
            className: css('!h-[102px]','1280:(!h-[134px])')
        },
        {
            alt: 'Users Love Us',
            id: 6,
            image: usersLoveUs
        },
        {
            alt: 'High Performer Award',
            id: 7,
            image: highPerformer,
            className: css('!h-[102px]','1280:(!h-[134px])')
        }
    ]
};
